.Card {
    width: auto;
    min-width: 40%;
    height: auto;
    max-height: 80%;
    background-color: #F6F6F6;
    padding: 2.5%;
    border-radius: 5px;
    scrollbar-width: none;
    overflow: auto;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}